import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {AppRequiredIosComponent} from './app-required-ios/app-required-ios.component';
import {RouterModule} from '@angular/router';
import {UiModule} from '../ui/ui.module';


@NgModule({
  declarations: [
    AppRequiredIosComponent
  ],
    imports: [
        CommonModule,
        RouterModule.forChild([
            {
                path: 'ios-app-required',
                component: AppRequiredIosComponent,
            }
        ]),
        UiModule,
    ],
})
export class AppRequiredModule {
}
