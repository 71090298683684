import {Component, OnDestroy, OnInit} from '@angular/core';
import {DialogRef} from '@angular/cdk/dialog';

@Component({
  selector: 'app-update-available-dialog',
  templateUrl: './update-available-dialog.component.html',
  styleUrls: ['./update-available-dialog.component.scss']
})
export class UpdateAvailableDialogComponent implements OnInit, OnDestroy {

  timer;

  constructor(private dialogRef: DialogRef<UpdateAvailableDialogComponent>) {
  }

  ngOnInit() {
    this.timer = setTimeout(() => this.update(), 10000);
  }

  ngOnDestroy() {
    clearTimeout(this.timer);
  }

  update() {
    this.dialogRef.close();
    document.location.reload();
  }
}
