import {APP_INITIALIZER, NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {UpdateAvailableDialogComponent} from './update-available-dialog/update-available-dialog.component';
import {UpdateService} from './update.service';
import {UiModule} from '../ui/ui.module';
import {MatSnackBarModule} from '@angular/material/snack-bar';
import * as Sentry from '@sentry/angular';

export function initUpdatesFactory(update: UpdateService) {
  return async () => update.startUpdateChecking();
}

@NgModule({
  declarations: [UpdateAvailableDialogComponent],
  imports: [
    CommonModule,
    MatSnackBarModule,
    UiModule,
  ],
  providers: [
    {
      provide: APP_INITIALIZER,
      multi: true,
      useFactory: initUpdatesFactory,
      deps: [UpdateService],
    },
  ]
})
export class UpdateModule {
  constructor(private updateService: UpdateService) {
    this.update();
  }

  async update() {
    try {
      await this.updateService.checkAndUpdate();
    } catch (e) {
      console.error('Application updating failed', e);
      Sentry.captureException(e);
    }
  }
}
