import {EventEmitter, Injectable} from '@angular/core';
import {MatSnackBar, MatSnackBarRef} from '@angular/material/snack-bar';
import {debounceTime} from 'rxjs/operators';
import {ConnectionAlertComponent} from './connection-alert/connection-alert.component';

@Injectable({
  providedIn: 'root'
})
export class OfflineNotifierService {

  offline = new EventEmitter();

  private alert?: MatSnackBarRef<ConnectionAlertComponent>;

  constructor(private snackBar: MatSnackBar) {
    window.addEventListener('offline', () => this.offline.emit(true));
    window.addEventListener('online', () => this.offline.emit(false));
  }

  watchConnection() {
    this.offline
      .pipe(debounceTime(200))
      .subscribe(result => result ? this.open() : this.dismiss());
  }

  open() {
    this.alert = this.snackBar.openFromComponent(ConnectionAlertComponent, {
      panelClass: 'alert',
    });
  }

  dismiss() {
    this.alert?.dismiss();
  }
}
