<header>
  <h2>Упс..</h2>
</header>

<div class="content">
  <p>Запуск игры через браузер на этом устройстве не поддерживается.</p>
  <p>Пожалуйста, установите приложение по этой кнопке и снова перейдите по ссылке на игру.</p>
  <p>Всё получится!</p>
</div>

<div class="actions">
  <a
    href="https://apps.apple.com/app/%D0%BA%D0%B2%D0%B5%D1%81%D1%82%D0%B8%D0%BA%D1%81-%D0%B2%D0%B8%D0%BA%D1%82%D0%BE%D1%80%D0%B8%D0%BD%D0%B0/id6446196304"
    target="_blank">
    <img src="/assets/images/appstore.svg" alt="Apple AppStore">
  </a>
</div>
