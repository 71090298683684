import * as Types from '../../api.graphql.g';

import { gql } from 'apollo-angular';
import { Injectable } from '@angular/core';
import * as Apollo from 'apollo-angular';
export type LogoutMutationVariables = Types.Exact<{ [key: string]: never; }>;


export type LogoutMutation = { __typename?: 'Mutation', logout?: any | null };

export type AuthRefreshMutationVariables = Types.Exact<{
  refreshToken: Types.Scalars['String'];
}>;


export type AuthRefreshMutation = { __typename?: 'Mutation', authRefresh: { __typename?: 'AuthCredentials', accessToken: string, refreshToken: string } };

export const LogoutDocument = gql`
    mutation logout {
  logout
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class LogoutGQL extends Apollo.Mutation<LogoutMutation, LogoutMutationVariables> {
    document = LogoutDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const AuthRefreshDocument = gql`
    mutation authRefresh($refreshToken: String!) {
  authRefresh(refreshToken: $refreshToken) {
    accessToken
    refreshToken
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class AuthRefreshGQL extends Apollo.Mutation<AuthRefreshMutation, AuthRefreshMutationVariables> {
    document = AuthRefreshDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }