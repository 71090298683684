import {ErrorHandler, Injectable, InjectionToken, Injector} from '@angular/core';

export const ERROR_HANDLERS = new InjectionToken<ErrorHandler[]>('ERROR_HANDLERS');

@Injectable({providedIn: 'root'})
export class ErrorHandlerManager implements ErrorHandler {
  constructor(private injector: Injector) {
  }

  handleError(error: any): void {
    const handlers = this.injector.get(ERROR_HANDLERS, []);
    handlers.forEach(handle => handle.handleError(error));
  }
}
