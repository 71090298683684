import { Component } from '@angular/core';

@Component({
  selector: 'app-connection-alert',
  templateUrl: './connection-alert.component.html',
  styleUrls: ['./connection-alert.component.scss']
})
export class ConnectionAlertComponent {

}
