import {Component} from '@angular/core';
import {Title} from '@angular/platform-browser';
import {environment} from '../environments/environment';
import {GoogleTagManagerService} from 'angular-google-tag-manager';
import {NavigationCancel, NavigationEnd, NavigationError, Router} from '@angular/router';
import {filter, take} from 'rxjs/operators';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  constructor(private router: Router,
              title: Title,
              gtmService: GoogleTagManagerService) {
    title.setTitle(environment.title);

    this.router.events.pipe(
      filter(
        (e) =>
          e instanceof NavigationEnd ||
          e instanceof NavigationCancel ||
          e instanceof NavigationError
      ),
      take(1),
    ).subscribe(() => {
      document.getElementById('main-loader').classList.add('hidden');
    });

    if (environment.production) {
      this.router.events.forEach(item => {
        if (item instanceof NavigationEnd) {
          gtmService.pushTag({
            event: 'page',
            pageName: item.url
          });
        }
      });
    }
  }
}
