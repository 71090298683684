import {AuthCredentials} from '../../api.graphql.g';

export namespace AuthAction {
  export class Init {
    public static readonly type = '[Auth] Init';
  }

  export class Login {
    public static readonly type = '[Auth] Login';

    constructor(public credentials: AuthCredentials) {
    }
  }

  export class Logout {
    public static readonly type = '[Auth] Logout';
  }
}
