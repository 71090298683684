import * as Sentry from '@sentry/browser';
import {onError} from '@apollo/client/link/error';
import {from} from '@apollo/client/core';
import {print} from 'graphql';
import {SentryLink} from 'apollo-link-sentry';
import {environment} from '../../environments/environment';

const errorLink = onError(({graphQLErrors, operation}) => {
  if (graphQLErrors?.length) {
    const definition = operation.query.definitions[0];
    Sentry.captureException(new Error('GraphQL Error'), {
      level: 'warning',
      extra: {
        query: definition.loc?.source?.body ?? print(definition),
        variables: operation.variables,
        errors: graphQLErrors,
      },
    });
  }
});

export const sentryLink = from([
  errorLink,
  new SentryLink({
    uri: environment.apiUrl,
    attachBreadcrumbs: {
      includeQuery: true,
      includeVariables: true,
      includeFetchResult: true,
      includeError: true,
    }
  }),
]);
