import {Injectable} from '@angular/core';
import {Router} from '@angular/router';
import {AuthService} from './auth/auth.service';
import {GameStatusGQL} from './app.graphql.g';
import {GameStatus, ParticipantRole} from '../api.graphql.g';
import {catchError, map} from 'rxjs/operators';
import {of} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class GameGuard {
  constructor(private router: Router,
              private auth: AuthService,
              private gameStatusGQL: GameStatusGQL) {
  }

  canActivate() {
    if (this.auth.isAuthenticated) {
      return this.gameStatusGQL.fetch().pipe(
        map(result => result.data.participant),
        map(participant => {
          if (participant?.game?.status === GameStatus.Started) {
            switch (participant.role) {
              case ParticipantRole.Host:
                return this.router.createUrlTree(['/host']);
              case ParticipantRole.Player:
                return this.router.createUrlTree(['/player']);
            }
          }
          return this.connectUrlTree;
        }),
        catchError(() => of(this.connectUrlTree)),
      );
    }
    return this.connectUrlTree;
  }

  get connectUrlTree() {
    return this.router.createUrlTree(['/player/connect']);
  }
}
