import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {ButtonComponent} from './button/button.component';
import {InputComponent} from './input/input.component';
import {AvatarComponent} from './avatar/avatar.component';
import {ProgressComponent} from './progress/progress.component';
import {ErrorComponent} from './error/error.component';
import {NetworkErrorDialogComponent} from './network-error-dialog/network-error-dialog.component';
import {MatDialogModule} from '@angular/material/dialog';
import {TimerComponent} from './timer/timer.component';
import {AnimationComponent} from './animation/animation.component';
import {LottieCacheModule, LottieModule} from 'ngx-lottie';
import {BackgroundCircleComponent} from './background-circles/background-circle/background-circle.component';
import {BackgroundCirclesComponent} from './background-circles/background-circles.component';
import {AudioDirective} from './audio/audio.directive';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import {ButtonIconComponent} from './button-icon/button-icon.component';
import {MatIconModule} from '@angular/material/icon';
import {SkeletonModule} from './skeleton/skeleton.module';
import {LoaderComponent} from './loader/loader.component';
import {AutofocusDirective} from './autofocus.directive';

export function lottiePlayerFactory() {
  return import('lottie-web');
}

@NgModule({
  declarations: [
    ButtonComponent,
    InputComponent,
    AvatarComponent,
    ProgressComponent,
    ErrorComponent,
    NetworkErrorDialogComponent,
    TimerComponent,
    AnimationComponent,
    BackgroundCirclesComponent,
    BackgroundCircleComponent,
    AudioDirective,
    ButtonIconComponent,
    LoaderComponent,
    AutofocusDirective,
  ],
  exports: [
    ButtonComponent,
    InputComponent,
    AvatarComponent,
    ProgressComponent,
    ErrorComponent,
    TimerComponent,
    AnimationComponent,
    BackgroundCirclesComponent,
    AudioDirective,
    ButtonIconComponent,
    SkeletonModule,
    LoaderComponent,
    AutofocusDirective,
  ],
  imports: [
    CommonModule,
    MatDialogModule,
    LottieModule.forRoot({
      player: lottiePlayerFactory
    }),
    LottieCacheModule.forRoot(),
    MatProgressSpinnerModule,
    MatIconModule,
    SkeletonModule,
  ]
})
export class UiModule {
}
