import {NgModule} from '@angular/core';
import {Apollo, ApolloModule} from 'apollo-angular';
import {from, InMemoryCache, split} from '@apollo/client/core';
import {HttpLink} from 'apollo-angular/http';
import {getMainDefinition} from '@apollo/client/utilities';
import {environment} from '../../environments/environment';
import introspectionResult from '../../api.graphql.g';
import {AuthService} from '../auth/auth.service';
import {AuthModule} from '../auth/auth.module';
import {RetryLink} from '@apollo/client/link/retry';
import {HttpErrorResponse} from '@angular/common/http';
import {GraphQLWsLink} from '@apollo/client/link/subscriptions';
import {createClient} from 'graphql-ws';
import {sentryLink} from './sentry.link';
import {AuthLink} from '../auth/auth.link';

@NgModule({
  imports: [
    ApolloModule,
    AuthModule,
  ]
})
export class GraphQLModule {
  constructor(apollo: Apollo,
              httpLink: HttpLink,
              authService: AuthService,
              authLink: AuthLink) {

    const http = httpLink.create({
      uri: environment.apiUrl,
    });

    const retryLink = new RetryLink({
      delay: {
        initial: 300,
        max: Infinity,
        jitter: true
      },
      attempts: {
        max: 10,
        retryIf: error => error instanceof HttpErrorResponse && !error.ok
      }
    });

    const wsClient = createClient({
      url: environment.socketUrl,
      shouldRetry: (event: Event) => event.type === 'close' || event.type === 'error',
      retryAttempts: Infinity,
      lazy: true,
      keepAlive: 5000,
      connectionParams() {
        const authToken = authService.accessToken;
        const clientId = authService.clientId;
        return {authToken, clientId};
      }
    });

    const wsLink = new GraphQLWsLink(wsClient);

    apollo.create({
      defaultOptions: {
        watchQuery: {
          fetchPolicy: 'network-only',
          nextFetchPolicy: 'cache-only',
        }
      },
      link: from([
        sentryLink,
        split(
          ({query}) => {
            const definition = getMainDefinition(query);
            return (
              definition.kind === 'OperationDefinition' && definition.operation === 'subscription'
            );
          },
          wsLink,
          from([
            retryLink,
            authLink.create(),
            http
          ]),
        ),
      ]),
      cache: new InMemoryCache({
        possibleTypes: introspectionResult.possibleTypes,
      }),
      connectToDevTools: !environment.production,
    });
  }
}
