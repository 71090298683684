import { Component } from '@angular/core';

@Component({
  selector: 'app-app-required-ios',
  templateUrl: './app-required-ios.component.html',
  styleUrls: ['./app-required-ios.component.scss']
})
export class AppRequiredIosComponent {

}
