import {Injectable} from '@angular/core';
import {map} from 'rxjs/operators';
import {AuthAction} from './auth.actions';
import {AuthRefreshGQL, LogoutGQL} from './auth.graphql.g';
import {Store} from '@ngxs/store';
import {AuthState} from './auth.state';
import {firstValueFrom} from 'rxjs';
import {AuthCredentials} from '../../api.graphql.g';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  constructor(private store: Store,
              private logoutGQL: LogoutGQL,
              private authRefreshGQL: AuthRefreshGQL) {
  }

  get clientId() {
    return this.store.selectSnapshot(AuthState.clientId);
  }

  get accessToken() {
    return this.store.selectSnapshot(AuthState.accessToken);
  }

  get refreshToken() {
    return this.store.selectSnapshot(AuthState.refreshToken);
  }

  get isAuthenticated() {
    return this.store.selectSnapshot(AuthState.isAuthenticated);
  }

  init() {
    return firstValueFrom(this.store.dispatch(new AuthAction.Init()));
  }

  auth(credentials: AuthCredentials) {
    return this.store.dispatch(new AuthAction.Login(credentials));
  }

  async refresh() {
    const refreshToken = this.refreshToken;
    const credentials = await firstValueFrom(this.authRefreshGQL.mutate({refreshToken}).pipe(
      map(result => result.data.authRefresh),
    ));
    await firstValueFrom(this.auth(credentials));
  }

  async reset() {
    return this.store.dispatch(new AuthAction.Logout());
  }

  async logout() {
    await firstValueFrom(this.logoutGQL.mutate());
    return this.reset();
  }
}
