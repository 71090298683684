import {APP_INITIALIZER, NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {NgxsModule} from '@ngxs/store';
import {AuthState} from './auth.state';
import {AuthService} from './auth.service';

export function initializeAuth(auth: AuthService) {
  return () => auth.init();
}

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    NgxsModule.forFeature([AuthState])
  ],
  providers: [
    {
      provide: APP_INITIALIZER,
      useFactory: initializeAuth,
      deps: [AuthService],
      multi: true,
    }
  ],
})
export class AuthModule {
}
