import * as Types from '../api.graphql.g';

import { gql } from 'apollo-angular';
import { Injectable } from '@angular/core';
import * as Apollo from 'apollo-angular';
export type GameStatusQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type GameStatusQuery = { __typename?: 'Query', participant?: { __typename?: 'ParticipantHost', id: string, role: Types.ParticipantRole, game: { __typename?: 'FibbageGame', id: string, status: Types.GameStatus } | { __typename?: 'QuizGame', id: string, status: Types.GameStatus } } | { __typename?: 'ParticipantPlayer', id: string, role: Types.ParticipantRole, game: { __typename?: 'FibbageGame', id: string, status: Types.GameStatus } | { __typename?: 'QuizGame', id: string, status: Types.GameStatus } } | null };

export const GameStatusDocument = gql`
    query gameStatus {
  participant {
    id
    role
    game {
      id
      status
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class GameStatusGQL extends Apollo.Query<GameStatusQuery, GameStatusQueryVariables> {
    document = GameStatusDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }