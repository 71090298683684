import {APP_INITIALIZER, NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {OfflineNotifierService} from './offline-notifier.service';
import {ConnectionAlertComponent} from './connection-alert/connection-alert.component';
import {MatIconModule} from '@angular/material/icon';

export function watchConnectionFactory(offline: OfflineNotifierService) {
  return () => {
    offline.watchConnection();
  };
}

@NgModule({
  declarations: [
    ConnectionAlertComponent
  ],
  imports: [
    CommonModule,
    MatIconModule
  ],
  providers: [
    {
      provide: APP_INITIALIZER,
      multi: true,
      useFactory: watchConnectionFactory,
      deps: [OfflineNotifierService],
    },
  ]
})
export class ConnectionModule {
}
