import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {DuplicateDirective} from './duplicate.directive';
import {SkeletonCircleComponent} from './skeleton-circle/skeleton-circle.component';
import {SkeletonLineComponent} from './skeleton-line/skeleton-line.component';
import {SkeletonAreaComponent} from './skeleton-area/skeleton-area.component';


@NgModule({
  declarations: [
    DuplicateDirective,
    SkeletonCircleComponent,
    SkeletonLineComponent,
    SkeletonAreaComponent,
  ],
  exports: [
    DuplicateDirective,
    SkeletonCircleComponent,
    SkeletonLineComponent,
    SkeletonAreaComponent,
  ],
  imports: [
    CommonModule
  ]
})
export class SkeletonModule {
}
